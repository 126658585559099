import React from 'react';
import FooterIcon from '../Icons/FooterIcon';
import styles from './Footer.module.scss';

function Footer() {
  return (
    <div className={styles.footer}>
      {/* <span className={styles.footerLabel}>2021 &copy; Flatlogic. Hand-crafted & Made with</span> */}
      {/* <FooterIcon /> */}
    </div>
  );
}

export default Footer;
